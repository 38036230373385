import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import contatoStyles from './contato.module.scss'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ContatoPage = () => {
  const data = useStaticQuery(graphql`
    query {
      contatoImagem: file(relativePath: { eq: "human.jpg" }) {
        childImageSharp {
          fluid (quality: 50, maxWidth: 1920, maxHeight: 770, cropFocus: CENTER){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Contato" />
      <div className={contatoStyles.mainContent}>
        <div className={contatoStyles.contatoTexto}>
          <h1 className={contatoStyles.title}>
            Contato
          </h1>
          <p>Nossas informações para contato.</p>
        </div>
      </div>
      <Img fluid={data.contatoImagem.childImageSharp.fluid} />

      <div className={contatoStyles.mainContent}>
        <div className={contatoStyles.contatoContainer}>
          <div className={contatoStyles.infoContainer}>
            <h2 className={contatoStyles.tituloTopico}>Endereço</h2>
            <h4 className={contatoStyles.subtituloTopico}>Entrada para atendimento</h4>
            <p>Alameda Conde de Porto Alegre, 1100<br />
              Bairro Olímpico - São Caetano do Sul - SP<br />
              CEP 09561-000<br />
            </p>
            <h4 className={contatoStyles.subtituloTopico}>Entrada para instalação</h4>
            <p>Rua Ministro Laudo Ferreira de Camargo, 156<br />
              Bairro Olímpico - São Caetano do Sul - SP<br />
              CEP 09561-040<br />
            </p>
            
            <h2 className={contatoStyles.tituloTopico}>Email</h2>
            <p><a href="mailto:contato@cerruns.com.br">contato@cerruns.com.br</a></p>
            
          </div>
          <div className={contatoStyles.infoContainer}>
            <h2 className={contatoStyles.tituloTopico}>Telefones</h2>
            <p>Cel: (11) 96102-5562<br />
            WhatsApp: (11) 98815-0655<br />
            Fixo: (11) 2311-3395 / (11) 2311-3413<br /></p>
            
            <h2 className={contatoStyles.tituloTopico}>Horário de funcionamento</h2>
            <p>Devido às medidas contra o Covid-19, não estamos atendendo presencialmente.<br />
            Caso queria entrar em contato, por favor utilize os número de celular ou de WhatsApp acima.</p>
          </div>

          <iframe className={contatoStyles.mapa}
            title="Mapa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d975.1365976917126!2d-46.55791365119053!3d-23.635209697921827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5cd52d79873f%3A0x7a3ff103b8f47cdd!2sCERRUNS!5e0!3m2!1spt-BR!2sbr!4v1558982456484!5m2!1spt-BR!2sbr" >
          </iframe>
        </div>
      </div>
    </Layout>
  )
}

export default ContatoPage